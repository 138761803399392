import { apiHost, adminMail, captchaKey } from "./settingHost";
import { URLObject } from "./types/URLObject";
import {
  faAddressBook,
  faBalanceScale,
  faCalendarDays,
  faChalkboardUser,
  faGavel,
  faMoneyBill,
  faPaperclip,
  faRightFromBracket,
  faRightToBracket,
  faScrewdriverWrench,
  faTrophy,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

const URLS: Record<string, URLObject> = {
  members: {
    name: "Klasyfikacja",
    url: "/members",
    icon: faAddressBook,
    nested: {
      players: { name: "Zawodnicy", url: "/players/", icon: faUser },
      arbiters: { name: "Sędziowie", url: "/arbiters/", icon: faBalanceScale },
      trainers: { name: "Trenerzy", url: "/trainers/", icon: faChalkboardUser },
    },
  },
  clubs: { name: "Kluby", url: "/clubs/", icon: faUsers },
  events: {
    name: "Wydarzenia",
    url: "/events",
    icon: faCalendarDays,
    nested: {
      tournaments: { name: "Turnieje", url: "/tournaments/", icon: faTrophy },
      arbiterAssemblies: {
        url: "/arbiters/assemblies",
        name: "Szkolenia sędziowskie",
        icons: [faGavel, faUsers],
      },
    },
  },
  services: { name: "Opłaty", url: "/services/", icon: faMoneyBill },
  application: { name: "Wnioski", url: "/application/", icon: faPaperclip },
};

const ACCOUNT_URLS: Record<string, URLObject> = {
  login: { name: "Logowanie", url: "/login/", icon: faRightToBracket },
  logout: { name: "Wyloguj", url: "/logout/", icon: faRightFromBracket },
  panel: { name: "Panel", url: "/panel", icon: faScrewdriverWrench },
};

const memberProfile = "/member";

const NO_MENU_URLS = {
  home: "/",
  resetPass: "/reset-password",
  player: {
    player: memberProfile + "/player/",
    newPlayer: "/player/new/",
  },
  arbiter: {
    arbiter: memberProfile + "/arbiter/",
    newArbiter: "/arbiter/new/",
    newAssembly: "/arbiter/assembly/new",
    assembly: "/arbiter/assembly/",
  },
  club: {
    club: "/club/",
    newClub: "/club/new/",
    confirmChange: "/confirm/club",
  },
  trainer: {
    trainer: memberProfile + "/trainer/",
    newTrainer: "/trainer/new",
  },
  tournament: {
    tournament: "/tournament/",
    newTournament: "/tournament/new/",
  },
  userManagement: "/users/managment/",
  changePassword: "/user/change/pass/",
  log: "/log/",
  confirm: {
    players: "/confirm/players/",
    tournaments: "/confirm/tournaments/",
  },
  backups: "/backups/",
  financialReport: {
    financialReports: "/reports/",
    financialReport: "/report/",
    newFinancialReport: "/report/new",
    unpaidService: "/unpaid-services",
  },
  confirmLicenses: "/confirm/licenses",
  confirmTitles: "/confirm/titles",
  fideProfile: "https://ratings.fide.com/profile/",
};

const API = {
  host: apiHost,
  login: "/login.php",
  logout: "/logout.php",
  resetPass: "/role/update/reset_password.php",
  roles: "/roles.php",
  refreshSession: "/refresh_session.php",
  sportClasses: "/sport_classes.php",
  admins: "/admins.php",
  distinctions: {
    update: {
      player: "/distinction/update/player.php",
    },
    list: "/distinction/list.php",
    player: "/distinction/player.php",
  },
  player: {
    data: "/player/data.php",
    decodedPlayers: "/player/players_decoded.php",
    insert: {
      player: "/player/insert/player.php",
      norm: "/player/insert/norm.php",
      sportClass: "/player/insert/sport_classes.php",
      licenseApplication: "/player/insert/license_application.php",
      updateForm: "/player/insert/update_form.php",
    },
    paidHistory: "/player/paid_history.php",
    count: "/player/players_count.php",
    players: "/player/players.php",
    allPlayers: "/player/players_all.php",
    remarks: "/player/remarks.php",
    chechFideIds: "/player/check_fide_ids.php",
    update: {
      player: "/player/update/player.php",
      remarks: "/player/update/remarks.php",
      paidHistory: "/player/update/paid_history.php",
      sportClassesHistory: "/player/update/sport_classes_history.php",
      confirmUpdateForm: "/player/update/confirm_update_form.php",
      confirmTitles: "/player/update/confirm_title.php",
    },
    registerForm: "/player/register_form.php",
    confirm: "/player/confirm.php",
    confirmLicense: "/player/confirm_license_application.php",
    sportClasses: "/player/sport_classes_history.php",
    sportClassesUpdate: "/player/update/sport_classes_history.php",
    suspend: "/player/update/suspend.php",
    massSuspend: "/player/update/mass_suspend.php",
    delete: "/player/delete.php",
    licenseApplications: "/player/license_applications.php",
    updateForms: "/player/update_forms.php",
    updateFormsCount: "/player/update_forms_count.php",
    notConfirmedTitles: "/player/not_confirmed_titles.php",
  },
  titles: "/titles.php",
  updateTitles: "/update_titles.php",
  updateTitlesArbiter: "/update_titles_arbiter.php",
  updateTitlesTrainer: "/update_titles_trainer.php",
  titlesHistoryUpdate: "/player/update/titles_history.php",
  titlesHistory: "/player/titles_history.php",
  club: {
    clubs: "/club/clubs.php",
    data: "/club/data.php",
    history: "/club/history.php",
    historyUpdate: "/club/update/history.php",
    members: "/club/members.php",
    membersUpdate: "/club/update/members.php",
    paidHistory: "/club/paid_history.php",
    paidUpdate: "/club/update/paid_history.php",
    remarks: "/club/remarks.php",
    remarksUpdate: "/club/update/remarks.php",
    update: "/club/update/club.php",
    insert: "/club/insert.php",
    suspend: "/club/update/suspend.php",
    delete: "/club/delete.php",
    insertChangeClubForm: "/club/update/change_club_form.php",
    changeClubForms: "/club/change_club_forms.php",
    confirmClubChange: "/club/update/confirm_club_change.php",
    unpaidTransfers: "/club/unpaid_transfers.php",
    generateTransferPayment: "/club/update/generate_payment.php",
  },
  arbiter: {
    arbiters: "/arbiter/arbiters.php",
    allArbiters: "/arbiter/arbiters_all.php",
    assemblies: "/arbiter/assemblies.php",
    assembliesCount: "/arbiter/assemblies_count.php",
    assembly: "/arbiter/assembly.php",
    count: "/arbiter/arbiters_count.php",
    data: "/arbiter/data.php",
    history: "/arbiter/history.php",
    historyUpdate: "/arbiter/update/history.php",
    titles: "/titles_arbiter.php",
    update: {
      arbiter: "/arbiter/update/arbiter.php",
      assembly: "/arbiter/update/assembly.php",
    },
    accountApplication: "/arbiter/account_application.php",
  },
  trainer: {
    count: "/trainer/trainers_count.php",
    data: "/trainer/data.php",
    history: "/trainer/history.php",
    historyUpdate: "/trainer/update/history.php",
    titles: "/titles_trainer.php",
    trainers: "/trainer/trainers.php",
    update: "/trainer/update/trainer.php",
  },
  tournament: {
    update: {
      fideTournament: "/tournament/update/fide_tournament.php",
      arbiters: "/tournament/update/arbiters.php",
      tournament: "/tournament/update/tournament.php",
      players: "/tournament/update/players.php",
      payments: "/tournament/update/payments.php",
      remarks: "/tournament/update/remarks.php",
      teams: "/tournament/update/teams.php",
    },
    select: {
      tournaments: "/tournament/tournaments.php",
      tournamentsCount: "/tournament/tournaments_count.php",
      data: "/tournament/data.php",
      players: "/tournament/players.php",
      norms: "/tournament/norms.php",
      sportClasses: "/tournament/sport_classes.php",
      payments: "/tournament/payments.php",
      remarks: "/tournament/remarks.php",
      teams: "/tournament/teams.php",
      archive: "/tournament/archive.php",
    },
    confirm: "/tournament/confirm.php",
    delete: "/tournament/delete.php",
    deleteFide: "/tournament/update/delete_fide_tournament.php",
  },
  role: {
    permissions: "/role/permissions.php",
    update: "/role/update/roles.php",
    changePassword: "/role/update/password.php",
  },
  logs: {
    logs: "/log/logs.php",
    log: "/log/log.php",
  },
  backup: {
    backups: "/backup/backup_list.php",
    create: "/backup/backup.php",
    download: "/backup/download.php",
    delete: "/backup/delete.php",
    import: "/backup/import.php",
  },
  countries: "/countries.php",
  generateArbiterLicense: "/generate_arbiter_license.php",
  generatePlayerLicense: "/generate_player_license.php",
  generateClubLicense: "/generate_club_license.php",
  generateTrainerLicense: "/generate_trainer_license.php",
  services: {
    services: "/services/services.php",
    tournament: "/services/tournament_services.php",
    transfer: "/services/transfer.php",
    penalties: "/services/penalties.php",
    update: {
      penalties: "/services/update/penalties.php",
      services: "/services/update/services.php",
      tournament: "/services/update/tournament_services.php",
      transfer: "/services/update/transfer.php",
    },
  },
  ratingList: {
    download: "/rating_list/download.php",
    generate: "/rating_list/generate_list.php",
  },

  financialReports: {
    reports: "/financial_reports/reports.php",
    report: "/financial_reports/report.php",
    notReportedPayments: "/financial_reports/not_reported_payments.php",
    new: "/financial_reports/new_report.php",
    unpaidServices: "/financial_reports/unpaid_services.php",
  },
  currencies: "/currencies.php",
  updateCurrencies: "/update_currency.php",
  refreshCurrencies: "/exchange_rates/refresh.php",
  tables: "/tables.php",
  refreshFide: "/refresh_fide_list.php",
  proformaInvoince: "/proforma_invoice.php",
};

const collator: Intl.Collator = new Intl.Collator("pl", {
  sensitivity: "variant",
});

const dateFormatter = new Intl.DateTimeFormat("pl-PL");

const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat("pl-PL", {
  style: "currency",
  currency: "PLN",
});

const SETTINGS = {
  URLS,
  adminMail,
  API,
  NO_MENU_URLS,
  ACCOUNT_URLS,
  collator,
  currencyFormatter,
  captchaKey,
  memberProfile,
  dateFormatter,
};

export default SETTINGS;
export {
  URLS,
  adminMail,
  API,
  NO_MENU_URLS,
  ACCOUNT_URLS,
  collator,
  currencyFormatter,
  captchaKey,
  memberProfile,
  dateFormatter,
};
