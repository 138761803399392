import { ACCOUNT_URLS, API } from "./settings";
import { toaster } from "evergreen-ui";
import { FormMethod } from "react-router-dom";
import { Permission } from "./types/Permission";
import { Province } from "./types/Province";
import axios, { AxiosRequestConfig, CancelTokenSource } from "axios";

/**
 * Fetches data from a specified URL using the fetch API.
 * @param {string} url - The URL to fetch data from.
 * @param {string} [method="get"] - The HTTP method to use for the request.
 * @param {T} [body={}] - The body of the request.
 * @returns {Promise<S>} - A promise condition that resolves to the fetched data.
 */
const fetchData = async <T extends object, S extends object>(
  url: string,
  method: FormMethod,
  body?: T,
  cancelToken?: CancelTokenSource,
): Promise<S> => {
  const config: AxiosRequestConfig = {
    method,
    url,
    cancelToken: cancelToken?.token,
  };

  if (method !== "get" && body) {
    config.data = body instanceof FormData ? body : JSON.stringify(body);
    if (!(body instanceof FormData)) {
      config.headers = { "Content-Type": "application/json" };
    }
  }

  try {
    const response = await axios({ ...config, method });
    return response.data as S;
  } catch {
    return [] as S;
  }
};

/**
 * Type for the checkPermissions function parameter.
 */
interface CheckPermissionsProps {
  token?: string;
  allowed?: string[];
}

/**
 * Checks if the user has the required permissions based on the token.
 * @param {CheckPermissionsProps} options - The options object.
 * @returns {Promise<void>} - A promise that resolves when the check is complete.
 */
const checkPermissions = async ({ token, allowed = [] }: CheckPermissionsProps): Promise<void> => {
  let granted = false;
  if (token) {
    const formData = new FormData();
    formData.append("token", token);
    const roles = await fetchData<FormData, Permission[]>(API.host + API.roles, "post", formData);
    for (const role of roles) {
      if (allowed.includes(role.rola)) {
        granted = true;
        break;
      }
    }
  }
  if (!granted) {
    window.location.href = ACCOUNT_URLS.login.url;
  }
};

/**
 * Type for the checkPermissions function parameter.
 */
interface checkIfSuperadminProps {
  token?: string;
}

/**
 * Checks if the user has the required permissions based on the token.
 * @param {checkIfSuperadminProps} options - The options object.
 * @returns {Promise<void>} - A promise that resolves when the check is complete.
 */
const checkIfSuperadmin = async ({ token }: checkIfSuperadminProps): Promise<void> => {
  let granted = false;
  if (token) {
    const formData = new FormData();
    formData.append("token", token);
    const roles = await fetchData<FormData, Permission[]>(API.host + API.roles, "post", formData);
    for (const role of roles) {
      if (role.rola === "superadministrator" && role.województwo === null) {
        granted = true;
        break;
      }
    }
  }
  if (!granted) {
    window.location.href = ACCOUNT_URLS.login.url;
  }
};

/**
 * Type for the fetchRoles function parameter.
 */
interface FetchRolesOptions {
  token: string;
  setSuperadmin?: (value: Province) => void;
  setArbiterCol?: (value: Province) => void;
  setAccountant?: (value: Province) => void;
  setKekir?: (value: Province) => void;
  setTrainerCol?: (value: Province) => void;
  allowed?: string[];
}

/**
 * Fetches roles based on the provided token and sets corresponding states.
 * @param {FetchRolesOptions} options - The options object.
 * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating if access is granted.
 */
const fetchRoles = async ({
  token,
  setSuperadmin = () => {},
  setArbiterCol = () => {},
  setAccountant = () => {},
  setKekir = () => {},
  setTrainerCol = () => {},
  allowed = [],
}: FetchRolesOptions): Promise<boolean> => {
  let granted = false;
  if (token) {
    const formData = new FormData();
    formData.append("token", token);
    const roles = await fetchData<FormData, Permission[]>(API.host + API.roles, "post", formData);
    for (const role of roles) {
      if (allowed.includes(role.rola)) {
        granted = true;
      }
      switch (role.rola) {
        case "KEKiR":
          setKekir(role["województwo"]);
          break;
        case "superadministrator":
          setSuperadmin(role["województwo"]);
          granted = true;
          break;
        case "kolegium sędziów":
          setArbiterCol(role["województwo"]);
          break;
        case "księgowość":
          setAccountant(role["województwo"]);
          break;
        case "kolegium trenerów":
          setTrainerCol(role["województwo"]);
          break;
        default:
          break;
      }
    }
  }
  return granted;
};

/**
 * Type for the fetchAndSet function parameter.
 */
interface FetchAndSetOptions<T extends object, S extends object> {
  url: string;
  setter: (data: S[]) => void;
  prefix?: S[];
  filter?: (item: S) => boolean;
  method?: FormMethod;
  body?: Partial<T>;
}

/**
 * Fetches data from a specified URL and sets it using the provided setter function.
 * @param {FetchAndSetOptions} options - The options object.
 * @returns {Promise<void>} - A promise that resolves when data is fetched and set.
 */
const fetchAndSet = async <T extends object, S extends object>({
  url,
  setter = () => {},
  prefix = [],
  filter = () => true,
  method = "get",
  body = {},
}: FetchAndSetOptions<T, S>): Promise<void> => {
  const data = await fetchData<Partial<T>, S[]>(url, method, body);
  setter([...prefix, ...data.filter(filter)]);
};

/**
 * Replaces national characters in a string with their ASCII equivalents.
 * @param {string} text - The text to process.
 * @returns {string} - The text with national characters replaced.
 */
const replaceNationalCharacters = (text: string): string => {
  try {
    let toReplace = text;
    toReplace = toReplace.replace(/ą/g, "a");
    toReplace = toReplace.replace(/Ą/g, "A");
    toReplace = toReplace.replace(/ć/g, "c");
    toReplace = toReplace.replace(/Ć/g, "C");
    toReplace = toReplace.replace(/ę/g, "e");
    toReplace = toReplace.replace(/Ę/g, "E");
    toReplace = toReplace.replace(/ł/g, "l");
    toReplace = toReplace.replace(/Ł/g, "L");
    toReplace = toReplace.replace(/ń/g, "n");
    toReplace = toReplace.replace(/Ń/g, "N");
    toReplace = toReplace.replace(/ó/g, "o");
    toReplace = toReplace.replace(/Ó/g, "O");
    toReplace = toReplace.replace(/ś/g, "s");
    toReplace = toReplace.replace(/Ś/g, "S");
    toReplace = toReplace.replace(/ź/g, "z");
    toReplace = toReplace.replace(/Ź/g, "Z");
    toReplace = toReplace.replace(/ż/g, "z");
    toReplace = toReplace.replace(/Ż/g, "Z");
    return toReplace;
  } catch {
    return text;
  }
};

interface fetchPaidHistoryProps<T extends object> {
  playerId: number;
  enitty: "zawodnik" | "sędzia" | "trener";
  setter?: (data: T[]) => void;
}
const fetchPaidHistory = <T extends object>({
  playerId,
  enitty,
  setter = () => {},
}: fetchPaidHistoryProps<T>): void => {
  const formData = new FormData();
  formData.append("playerid", playerId + "");
  formData.append("entity", enitty);
  fetchAndSet<FormData, T>({
    url: API.host + API.player.paidHistory,
    method: "post",
    body: formData,
    setter,
  });
};

interface simpleFetchProps {
  url: string;
  body: FormData | string;
  refreshData?: () => void;
  successMessage?: string;
  failMessage?: string;
}

const simpleFetch = ({
  url,
  body,
  refreshData = () => {},
  successMessage = "Pomyślnie zaktualizowano",
  failMessage = "Nie udało się zaktualizować",
}: simpleFetchProps): void => {
  axios
    .post(url, body, {
      headers: body instanceof FormData ? {} : { "Content-Type": "application/json" },
    })
    .then(() => {
      toaster.success(successMessage);
    })
    .catch(() => {
      toaster.danger(failMessage);
    })
    .finally(refreshData);
};

interface changeHistoryProps<T> {
  changed: T[];
  deleted: number[];
  url: string;
  token: string;
  refreshData?: () => void;
}

const changeHistory = <T>({
  changed,
  deleted,
  url,
  token,
  refreshData = () => {},
}: changeHistoryProps<T>): void => {
  if (changed.length === 0 && deleted.length === 0) {
    toaster.notify("Nic nie zmieniono");
    return;
  }
  simpleFetch({
    url,
    body: JSON.stringify({ token: token, changed, deleted }),
    refreshData,
  });
};

export {
  fetchData,
  fetchRoles,
  fetchAndSet,
  replaceNationalCharacters,
  checkPermissions,
  checkIfSuperadmin,
  changeHistory,
  fetchPaidHistory,
  simpleFetch,
};
