import React, { useContext, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "../../styles/Menu.scss";
import logo from "../../logo_white.svg";

import { ACCOUNT_URLS, NO_MENU_URLS } from "../../settings";
import GlobalContext from "../../GlobalContext";
import { URLObject } from "../../types/URLObject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab, TabNavigation } from "evergreen-ui";
import { faHome } from "@fortawesome/free-solid-svg-icons";

const isTabActive = (obj: URLObject, location: string): boolean =>
  new RegExp(`^${location}\\d*$`).test(obj.url) ||
  (obj.nested !== undefined &&
    Object.values(obj.nested).some((item) => isTabActive(item, location)));
interface MenuProps {
  links: Record<string, URLObject>;
}

const RecursiveMenu: React.FC<{ links: Record<string, URLObject>; main: boolean }> = ({
  links,
  main,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  let subMenu = {};
  return (
    <>
      <TabNavigation
        style={{ borderTop: main ? undefined : "solid black 2px", alignItems: "center" }}
      >
        {main && (
          <Link to={NO_MENU_URLS.home} className="desktop">
            <img className="desktop" loading="lazy" src={logo} alt="Logo" id="logo" />
            <span className="mobile">
              Strona głóna
              <FontAwesomeIcon icon={faHome} />
            </span>
          </Link>
        )}
        {Object.keys(links).map((key) => {
          const item = links[key];
          const isSelected = isTabActive(item, location.pathname);
          const hasChildren = item.nested && Object.keys(item.nested).length > 0;

          if (hasChildren && isSelected) {
            subMenu = { ...subMenu, ...item.nested };
          }
          return (
            <Tab
              href={item.url}
              is="a"
              isSelected={isSelected}
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                navigate(item.url);
              }}
              className={isSelected ? "active-tab" : ""}
            >
              {item.icon ? (
                <FontAwesomeIcon icon={item.icon} />
              ) : (
                item.icons &&
                item.icons.map((icon, index) => <FontAwesomeIcon key={index} icon={icon} />)
              )}{" "}
              {item.name}
            </Tab>
          );
        })}
      </TabNavigation>
      {Object.keys(subMenu).length > 0 && <RecursiveMenu links={subMenu} main={false} />}
    </>
  );
};

/**
 * Main Menu Component.
 */
const Menu: React.FC<MenuProps> = ({ links }) => {
  const { state } = useContext(GlobalContext)!;
  const expirationDate = state.expiration ? new Date(parseInt(state.expiration)) : null;

  const mergedLinks: Record<string, URLObject> = useMemo(
    () => ({
      ...links,
      ...(state.token && { panel: ACCOUNT_URLS.panel }),
      log: state.token ? ACCOUNT_URLS.logout : ACCOUNT_URLS.login,
    }),
    [state.token, links],
  );

  const menuContent = (
    <>
      <RecursiveMenu links={mergedLinks} main={true} />

      {state.token && state.user && (
        <div>
          Zalogowany jako {state.user}
          {expirationDate && (
            <>
              , sesja wygasa {("0" + expirationDate.getDate()).slice(-2)}.
              {("0" + (expirationDate.getMonth() + 1)).slice(-2)}.{expirationDate.getFullYear()}{" "}
              {("0" + expirationDate.getHours()).slice(-2)}:
              {("0" + expirationDate.getMinutes()).slice(-2)}:
              {("0" + expirationDate.getSeconds()).slice(-2)}
            </>
          )}
        </div>
      )}
    </>
  );
  return (
    <header>
      <details className="mobile">
        <summary>
          <div style={{ display: "inline-flex", alignItems: "center" }}>
            Menu <img loading="lazy" src={logo} alt="Logo" id="logo" />
          </div>
        </summary>

        <div>{menuContent}</div>
      </details>

      <div className="desktop">{menuContent}</div>
    </header>
  );
};

export default Menu;
